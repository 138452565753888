/* eslint-disable import/no-extraneous-dependencies */
import { Route, Switch } from '@leagueplatform/routing';
import { getModulePath, LEAGUE_MODULE_NAMES } from '@leagueplatform/core';
import { AuthViewLogin, AuthViewLogout } from 'pages/auth/Auth.pages';

import {
  LandingPage,
  CareCollectionsRoutes,
  CareDiscoveryRoutes,
  HealthJourneyRoutes,
  HealthNeedPages,
  HealthProfileRoutes,
  AboutMeRoutes,
  Home,
  OnboardingRoutesWithProvider,
  WalletRoutes,
  ClaimsRoutes,
} from 'components/LazyRoutes';

import ErrorPage from 'pages/Error404';
import { PATHS } from 'commons/constants';
import { AboutUsPage } from 'pages/about-us/about-us.page';
import { FormsPage } from 'pages/forms/Forms.page';
import { ContactUsPage } from 'pages/contact-us/contact-us.page';
import { PersonalInformationPage } from 'pages/PersonalInformationPage';
import { CommunicationPreferencesPage } from 'pages/communication-preferences/communication-preferences.page';
import { PrivateRoutesContainer } from './private-routes-container/private-routes-container.component';
import { AuthSessionContainer } from './auth-session-container/auth-session-container';

function AppRoutes() {
  return (
    <Switch>
      <Route path="/sign-in" component={AuthViewLogin} />
      <Route path="/sign-out" component={AuthViewLogout} />
      <Route path="/" exact component={LandingPage} />
      <AuthSessionContainer>
        <PrivateRoutesContainer>
          <Switch>
            <Route path="/home" component={Home} exact key="home" />
            <Route
              path={[
                getModulePath(LEAGUE_MODULE_NAMES.healthJourney),
                getModulePath(LEAGUE_MODULE_NAMES.healthPrograms),
                getModulePath(LEAGUE_MODULE_NAMES.healthProgress),
                getModulePath(LEAGUE_MODULE_NAMES.healthJourneyHistory),
              ]}
              component={HealthJourneyRoutes}
              key="health-journey"
            />
            <Route
              path={getModulePath(LEAGUE_MODULE_NAMES.careCollections)}
              component={CareCollectionsRoutes}
              key="care-collections"
            />
            <Route
              path={getModulePath(LEAGUE_MODULE_NAMES.claims)}
              component={ClaimsRoutes}
              key="claims"
            />
            <Route
              path={getModulePath(LEAGUE_MODULE_NAMES.careDiscovery)}
              component={CareDiscoveryRoutes}
              key="care-discovery"
            />
            <Route
              path={getModulePath(LEAGUE_MODULE_NAMES.healthNeedPages)}
              component={HealthNeedPages}
              key="health-needs"
            />
            <Route
              path={getModulePath(LEAGUE_MODULE_NAMES.wallet)}
              component={WalletRoutes}
              key="wallet"
            />
            <Route
              path={getModulePath(LEAGUE_MODULE_NAMES.healthProfile)}
              component={HealthProfileRoutes}
              key="health-profile"
            />
            <Route
              path={getModulePath(LEAGUE_MODULE_NAMES.aboutMe)}
              component={AboutMeRoutes}
              key="about-me"
            />
            <Route path={PATHS.FORMS} component={FormsPage} key="forms" />
            <Route
              path={PATHS.CONTACT_US}
              component={ContactUsPage}
              key="contact-us"
              exact
            />
            <Route
              path={PATHS.PERSONAL_INFO}
              component={PersonalInformationPage}
            />
            <Route
              path={PATHS.COMMUNICATION_PREFERENCES}
              component={CommunicationPreferencesPage}
              exact
            />
            <Route path={PATHS.ABOUT} component={AboutUsPage} exact />
            <Route
              path={getModulePath(LEAGUE_MODULE_NAMES.onboarding)}
              component={OnboardingRoutesWithProvider}
              key="onboarding"
            />

            <Route component={ErrorPage} />
          </Switch>
        </PrivateRoutesContainer>
      </AuthSessionContainer>
    </Switch>
  );
}

export default AppRoutes;
