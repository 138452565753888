import { Suspense } from 'react';

import { ReactQueryDevtools } from 'react-query/devtools';
import { ErrorBoundary } from 'react-error-boundary';
import { BrowserRouter } from '@leagueplatform/routing';
import League from '@leagueplatform/core';
import { useOnlineStatus } from '@leagueplatform/web-common';
import {
  MasonryDriverNodeRenderersRegistry,
  MasonryWSDriverNodeRenderersRegistry,
} from '@leagueplatform/masonry';
import { ActivityStackWidgetV2 } from '@leagueplatform/health-journey';
import {
  FullPageError,
  LoadingIndicator,
  ErrorState,
} from '@leagueplatform/web-common-components';
import { PCPCustomSectionRenderer } from 'components/masonry/pcp-custom-section-renderer.component';
import AppRoutes from './components/AppRoutes';
import { config, routesConfig } from './config';

League.overwriteRoutePaths(routesConfig);

MasonryWSDriverNodeRenderersRegistry.registerRenderer(
  'scfhp',
  'your_care_team_stack',
  PCPCustomSectionRenderer,
);

MasonryDriverNodeRenderersRegistry.registerRenderer(
  'health_journey',
  'activity_stack',
  ActivityStackWidgetV2,
);

export function App() {
  return (
    <League.LeagueProvider config={config}>
      <ErrorBoundary fallback={<ErrorState />}>
        <Suspense fallback={<LoadingIndicator />}>
          {useOnlineStatus().isOffline && <FullPageError route="/home" />}
          {useOnlineStatus().isOnline && (
            <BrowserRouter>
              <AppRoutes />
            </BrowserRouter>
          )}
          <ReactQueryDevtools />
        </Suspense>
      </ErrorBoundary>
    </League.LeagueProvider>
  );
}
