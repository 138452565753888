import { forwardRef, Ref } from 'react';
import {
  Button,
  StackLayout,
  UtilityText,
  styled,
} from '@leagueplatform/genesis-core';
import { useIntl } from '@leagueplatform/locales';

const FooterSubmitContainer = styled('div', {
  position: 'fixed',
  left: '0',
  bottom: '0',
  width: '100%',
  backgroundColor: '$surfaceBackgroundPrimary',
  boxShadow: '0px -2px 4px rgba(0, 0, 0, 0.1)',
});

export const FixedFormFooter = forwardRef(
  (
    {
      back,
      next,
      dirty,
      isLoading,
      submitText,
    }: {
      back: () => void;
      next: () => void;
      dirty?: boolean;
      isLoading?: boolean;
      submitText: string;
    },
    ref: Ref<HTMLDivElement>,
  ) => {
    const { formatMessage } = useIntl();
    return (
      <FooterSubmitContainer>
        <StackLayout
          ref={ref}
          orientation={{ '@initial': 'horizontal', '@mobile': 'vertical' }}
          verticalAlignment="center"
          horizontalAlignment={{ '@initial': 'start', '@mobile': 'stretch' }}
          css={{
            paddingInline: '$twoAndHalf',
            maxWidth: 1200,
            marginInline: 'auto',
            paddingBlock: '$one',
            '@mobile': {
              paddingInline: '$oneAndHalf',
              paddingBlock: '$oneAndHalf $one',
            },
          }}
        >
          {dirty && (
            <div role="region" aria-live="polite">
              <UtilityText
                as="p"
                emphasis="subtle"
                css={{
                  textAlign: 'center',
                  '@mobile': { marginBottom: '$oneAndHalf' },
                }}
              >
                {formatMessage({ id: 'STR_YOU_HAVE_UNSAVED' })}
              </UtilityText>
            </div>
          )}
          <StackLayout
            orientation={{ '@initial': 'horizontal', '@mobile': 'vertical' }}
            horizontalAlignment={{ '@initial': 'end', '@mobile': 'stretch' }}
            spacing="$one"
          >
            <Button
              priority="secondary"
              onClick={back}
              width={{ '@initial': 'hugContents', '@mobile': 'fillContainer' }}
              css={{
                borderRadius: '24px',
              }}
            >
              {formatMessage({ id: 'STR_BACK' })}
            </Button>
            <Button
              loading={isLoading}
              priority="primary"
              type="submit"
              onClick={next}
              width={{ '@initial': 'hugContents', '@mobile': 'fillContainer' }}
              css={{
                borderRadius: '24px',
              }}
            >
              {submitText}
            </Button>
          </StackLayout>
        </StackLayout>
      </FooterSubmitContainer>
    );
  },
);
