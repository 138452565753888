import * as React from 'react';
import { StackLayout, UtilityText, Box } from '@leagueplatform/genesis-core';
import { useIntl } from '@leagueplatform/locales';

export const MakeUpdateCard = () => {
  const { formatMessage } = useIntl();

  return (
    <StackLayout
      css={{
        padding: '$one',
        border: '1px solid #D6D6D6',
      }}
    >
      <Box
        css={{
          flex: 1,
          typography: '$headingFour',
          marginY: '$half',
        }}
      >
        {formatMessage({ id: 'STR_MAKE_AN_UPDATE' })}
      </Box>
      <UtilityText css={{ typography: '$bodyOne', marginY: '$half' }}>
        {`${formatMessage({ id: 'STR_SUNMIT_A_REQUEST' })} `}
        <a
          href="/"
          target="_blank"
          rel="noreferrer"
          style={{ fontWeight: 400 }}
        >
          {formatMessage({ id: 'STR_MEMBER_CONTACT_INFORMATION_CHANGE' })}
        </a>
        {` ${formatMessage({ id: 'STR_FORM' })}`}.
      </UtilityText>
    </StackLayout>
  );
};
