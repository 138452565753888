import { BoxProps } from '@leagueplatform/genesis-commons';
import { PATHS } from 'commons/constants';
import { Link } from 'commons/types';
import { customizeLinks } from './customize-links';

export type Options = {
  heading?: string;
  items: Link[];
};

const boldTextLink: BoxProps = {
  color: 'onSurface.text.subdued',
  fontWeight: 'bold',
};

const boldTextCriticalLink: BoxProps = {
  color: 'onSurface.text.critical',
  fontWeight: 'bold',
};

export const getDropdownLinks = (
  language: string,
  lob: string,
  handleDeleteAccount: () => void,
): Options[] => {
  // Base dropdown options
  const baseOptions: Options[] = [
    {
      heading: 'STR_ACCOUNT_SETTINGS',
      items: [
        {
          text: 'STR_PERSONAL_INFO',
          to: PATHS.PERSONAL_INFO,
          color: '$onSurfaceTextSubdued',
          linkIcon: 'interfaceChevronRight',
          linkProps: boldTextLink,
        },
        {
          text: 'STR_FORMS',
          to: PATHS.EN_SUBMIT_REQUEST_DUALCONNECT,
          linkProps: boldTextLink,
          color: '$onSurfaceTextSubdued',
          linkIcon: 'interfaceChevronRight',
        },
        {
          text: 'STR_COMMUNICATION',
          to: PATHS.COMMUNICATION_PREFERENCES,
          linkProps: boldTextLink,
          color: '$onSurfaceTextSubdued',
          linkIcon: 'interfaceChevronRight',
        },
      ],
    },
    {
      heading: 'ABOUT',
      items: [
        {
          text: 'STR_ABOUT_SANTA_CLARA',
          to: PATHS.ABOUT,
          linkProps: boldTextLink,
          color: '$onSurfaceTextSubdued',
          linkIcon: 'interfaceChevronRight',
        },
        {
          text: 'STR_PRIVACY_POLICY',
          to: PATHS.EN_PRIVACY_POLICY,
          target: '_blank',
          linkProps: boldTextLink,
          color: '$onSurfaceTextSubdued',
          linkIcon: 'interfaceExternalLink',
        },
        {
          text: 'STR_TERMS_AND_CONDITIONS',
          to: PATHS.EN_TERMS_OF_USE_ROUTE,
          target: '_blank',
          linkProps: boldTextLink,
          color: '$onSurfaceTextSubdued',
          linkIcon: 'interfaceExternalLink',
        },
        {
          text: 'STR_NON_DESCRIMINATORY_NOTICE',
          to: PATHS.EN_NON_DESCRIMINARTION_DUALCONNECT,
          target: '_blank',
          linkProps: boldTextLink,
          color: '$onSurfaceTextSubdued',
          linkIcon: 'interfaceExternalLink',
        },
        {
          text: 'STR_LANGUAGE_ASSISTANCE',
          to: PATHS.LANGUAGE_ASSISTANCE_DUALCONNECT,
          target: '_blank',
          linkProps: boldTextLink,
          color: '$onSurfaceTextSubdued',
          linkIcon: 'interfaceExternalLink',
        },
        {
          text: 'STR_DELETE_YOUR_ACCOUNT',
          onClick: handleDeleteAccount,
          linkProps: boldTextLink,
          color: '$onSurfaceTextSubdued',
          linkIcon: 'interfaceChevronRight',
        },
      ],
    },
    {
      items: [
        {
          text: 'STR_LOG_OUT',
          to: '/sign-out',
          color: '$onSurfaceTextCritical',
          linkProps: boldTextCriticalLink,
          linkIcon: 'interfaceChevronRight',
        },
      ],
    },
  ];

  // Apply customizations to the items in each section
  const customizedOptions = baseOptions.map((option) => ({
    ...option,
    items: customizeLinks(option.items, language, lob),
  }));

  return customizedOptions;
};
